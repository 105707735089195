import { Controller } from "@hotwired/stimulus";
import { csrfToken } from "helpers/csrf";
import Sortable from "sortablejs";

export default class extends Controller {
  static targets = ["sequence"];

  static values = { sequenceType: String };

  connect() {
    this.sortable = Sortable.create(this.element, this.sortOptions());
  }

  sortOptions() {
    return {
      handle: ".cursor-move",
      group: "shared",
      animation: 150,
      onEnd: this.updateSequence,
    }
  }

  updateSequence = async (event) => {
    try {
      const id = event.item.dataset.id;

      await fetch(`/admin/sequences/${id}`, {
        body: this.setFormData(event),
        method: "PATCH",
        headers: { "X-CSRF-Token": csrfToken() },
      })
    } catch (error) {
      this.alertError(error)
    }
  }

  setFormData(event) {
    const data = new FormData();
    data.append("sequence_position", event.newIndex);
    data.append("sequence_type", this.sequenceTypeValue);
    
    return data;
  }

  alertError(error) {
    alert(`Reorder failed with: ${error}. Please refresh the page and try again.`);
  }

  disconnect() {
    this.sortable.destroy();
  }
}