import { Controller } from '@stimulus/core';

export default class extends Controller {
  static targets = ['frame'];

  static values = { url: String };

  setSource(url) {
    console.log(url)

    this.frameTarget.setAttribute('src', url);
  }

  /**
   * Sets the src attribute of a turbo_frame_tag using the url value.
   */
  handleInputChange({currentTarget: { name, value }}) {
    console.log(name)

    const params = new URLSearchParams();
    params.append(name, value);
    this.setSource(`${this.urlValue}?${String(params)}`);
  }
}
