import {Application} from '@hotwired/stimulus';
import {definitionsFromContext} from '@hotwired/stimulus-webpack-helpers';
import Flatpickr from 'stimulus-flatpickr';

const application = Application.start();
const context = require.context('controllers', true, /\.|js|ts$/);
const contextComponents = require.context('../../components', true, /_controller\.|js|ts$/);
application.load(
  definitionsFromContext(context).concat(
    definitionsFromContext(contextComponents)
  )
);

application.register('flatpickr', Flatpickr);
