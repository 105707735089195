import { Controller } from '@hotwired/stimulus';
import { createPopper, Placement } from '@popperjs/core';

export default class extends Controller {
  static targets = ['popover', 'trigger'];

  static values = {
    hoverCloseDelay: Number,
    offset: Array,
    position: String,
  };

  declare readonly popoverTarget: HTMLElement;

  declare readonly triggerTarget: HTMLElement;

  declare readonly positionValue: Placement;

  declare readonly hoverCloseDelayValue: number;

  declare readonly offsetValue: number;

  private popperInstance: ReturnType<typeof createPopper>;

  private hideTimeout: ReturnType<typeof setTimeout>;

  private setPopoverHidden(hidden: boolean) {
    this.popoverTarget.classList.toggle('hidden', hidden);
  }

  public show() {
    clearTimeout(this.hideTimeout);
    this.setPopoverHidden(false);
    this.popperInstance.update();
  }

  public hide() {
    this.hideTimeout = setTimeout(() => {
      this.setPopoverHidden(true);
    }, this.hoverCloseDelayValue);
  }

  connect() {
    this.popperInstance = createPopper(this.triggerTarget, this.popoverTarget, {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: this.offsetValue,
          },
        },
      ],
      placement: this.positionValue,
    });
  }
}
