import Trix from "trix";

// Remove default filename and size from caption when editing
Trix.config.attachments.preview.caption = {
  name: false,
  size: false,
};

Trix.config.textAttributes.sup = { tagName: "sup", inheritable: true };

document.addEventListener("trix-initialize", function (event) {
  var groupElement = event.target.toolbarElement.querySelector(
    ".trix-button-group.trix-button-group--text-tools"
  );

  let existingButton = groupElement.querySelector("button[data-trix-attribute=sup]");
  if (existingButton === null) {
    groupElement.insertAdjacentHTML(
      "beforeend",
      '<button type="button" class="trix-button" data-trix-attribute="sup" title="SUP" tabindex="-1">SUP</button>'
    );
  }
});
